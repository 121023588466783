import React from "react";
import "./experience.css";
import { MdLibraryAddCheck } from "react-icons/md";

const experience = () => {
  return (
    <section id="exper">
      <h5>My Skills</h5>
      <h2>My Experience</h2>

      {/* first container */}
      <div className="container experience_container">
        {/* first container */}
        <div className="experience_mobile">
          <h3>Mobile </h3>
          <div className="expe_content">
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Flutter</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
              <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Sqlite</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
          
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Xamarin | MAUI</h4>
                <small className="text-light">Senior</small>
              </div>
            </article>{" "}
            
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Firebase</h4>
                <small className="text-light">Senior</small>
              </div>
            </article>{" "}
            
                <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Maps</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
                <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Azure</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
               <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Google Cloud</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
                      <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Bloc</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
               <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>MVVM</h4>
                <small className="text-light">Senior</small>
              </div>
            </article>{" "}      
         
          </div>
        </div>

        {/* web */}
        <div className="expe_web">
          <h3>Frontend </h3>
          <div className="expe_content">
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Html</h4>
                <small className="text-light">Mid</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Css</h4>
                <small className="text-light">Mid</small>
              </div>
            </article>{" "}
              <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>ASP MVC</h4>
                <small className="text-light">Junior</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Javascript</h4>
                <small className="text-light">Junior</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Angular 2</h4>
                <small className="text-light">Junior</small>
              </div>
            </article>{" "}
        
          </div>
        </div>

        <div className="expe_web">
          <h3>Backend</h3>
          <div className="expe_content marketing">
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Asp core</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
             <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Rest Api</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
                <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>IIS</h4>
                <small className="text-light">Junior</small>
              </div>
            </article>{" "}
              <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Soap Api</h4>
                <small className="text-light">Junior</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Sql Server</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>No Sql</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>C#</h4>
                <small className="text-light">Senior</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Signalr</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
          </div>
        </div>
        <div className="expe_web">
          <h3>UI/UX </h3>
          <div className="expe_content marketing">
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>User Interface Designer</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>User Experience</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Research</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Wireframes</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
            <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Prototyping</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
          </div>
           <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>XD</h4>
                <small className="text-light">Mid-Level</small>
              </div>
            </article>{" "}
       <article className="expe_details">
              <MdLibraryAddCheck className="expe-icons" />
              <div>
                <h4>Figma</h4>
                <small className="text-light">Junior</small>
              </div>
            </article>{" "}
          </div>
   
      </div>
    </section>
  );
};

export default experience;
