import React from 'react'

const cta = () => {
  return (
    <div className='CTA'>
        <a href="https://ahmedghaith.com/Ahmed%20Mohamed%20Ibrahim%20_Mobile_dotnet.pdf"    target="_blank" className='btn'> Downland CV</a>
        <a href="#contact" className='btn btn_primary'>Lets Talk</a>
        
    </div>
  )
}

export default cta